import { memo } from 'react';

import ArrowIosDown from '@crehana/compass.icons.outline/ArrowIosDown';
import Filter from '@crehana/compass.icons.outline/Filter';
import Sort from '@crehana/compass.icons.outline/Sort';
import {
  cn,
  Dropdown,
  DropdownContent,
  DropdownTextList,
  DropdownTrigger,
} from '@crehana/compass.ui/v2';
import { useMediaQuery } from '@crehana/react-hooks';
import { CuiOptionType } from '@crehana/ui';

import type { TCourseFilterOption } from '../MyCoursesWithRules/types';
import { FilterBySheet, OrderBySheet } from './components';
import { useFilterHeaderTranslation } from './hooks';
import { ButtonDropdown } from './ui';

export type FiltersProps = {
  className?: string;
  title?: string;
  filterOptions: CuiOptionType[];
  orderOptions: CuiOptionType[];
  filterSelected: string;
  orderSelected: string;
  onChangeFilter: (selected: TCourseFilterOption) => void;
  onChangeOrder: (selected: TCourseFilterOption) => void;
};

export const MyCoursesFilters: React.FC<FiltersProps> = ({
  className,
  title,
  filterOptions = [],
  orderOptions = [],
  filterSelected,
  orderSelected,
  onChangeFilter,
  onChangeOrder,
}) => {
  const { matches: isSm } = useMediaQuery('sm');
  const { t } = useFilterHeaderTranslation();

  const handleChangeOrder = (selected: string | number) => {
    const optionSelected = orderOptions.find(
      option => option.value === selected,
    );
    onChangeOrder(optionSelected as TCourseFilterOption);
  };

  const handleChangeFilter = (selected: string | number) => {
    const optionSelected = filterOptions.find(
      option => option.value === selected,
    );
    onChangeFilter(optionSelected as TCourseFilterOption);
  };

  return (
    <div
      className={cn(
        'tw-flex tw-flex-col tw-mb-1.6 sm:tw-mb-2.4',
        title &&
          'md:tw-flex-row-reverse md:tw-items-center md:tw-justify-between',
        className,
      )}
    >
      <div className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-items-center sm:tw-justify-end">
        <div>
          {isSm ? (
            <div className="tw-flex tw-gap-1.2">
              <Dropdown>
                <DropdownTrigger asChild>
                  <ButtonDropdown>
                    <Sort size={16} className="tw-fill-current" />
                    <span>{t('UD_ORDER_BY')}</span>
                    <ArrowIosDown className="tw-text-inherit" size={20} />
                  </ButtonDropdown>
                </DropdownTrigger>
                <DropdownContent>
                  <DropdownTextList
                    onSelect={handleChangeOrder}
                    items={orderOptions}
                    multiselect={false}
                    selected={orderSelected}
                    data-testid="order-by-dropdown"
                  />
                </DropdownContent>
              </Dropdown>

              <Dropdown>
                <DropdownTrigger asChild>
                  <ButtonDropdown>
                    <Filter size={16} className="tw-fill-current" />
                    <span>{t('UD_FILTER_LABEL')}</span>
                    <ArrowIosDown className="tw-text-inherit" size={20} />
                  </ButtonDropdown>
                </DropdownTrigger>
                <DropdownContent>
                  <DropdownTextList
                    onSelect={handleChangeFilter}
                    items={filterOptions}
                    multiselect={false}
                    selected={filterSelected}
                    classNameBase="tw-max-h-32"
                    data-testid="filter-by-dropdown"
                  />
                </DropdownContent>
              </Dropdown>
            </div>
          ) : (
            <div
              className={cn(
                'tw-flex tw-gap-1.2',
                'tw-fixed tw-bottom-0 tw-z-2',
                'tw-bg-neutral-light-100 tw-border-neutral-light-400',
                'dark:tw-bg-elevation-dark-04 dark:tw-border-elevation-dark-03',
                'tw-p-1.2 tw-rounded-1.2 tw-border-solid tw-border',
                'tw-bottom-1.2 tw-left-1.6 tw-right-1.6',
              )}
            >
              <OrderBySheet
                items={orderOptions}
                selected={orderSelected}
                onSelect={handleChangeOrder}
              />
              <FilterBySheet
                items={filterOptions}
                selected={filterSelected}
                onSelect={handleChangeFilter}
              />
            </div>
          )}
        </div>
      </div>
      {title && (
        <span className="tw-font-pop tw-text-1.6 tw-mt-2.4 md:tw-mt-0">
          {title}
        </span>
      )}
    </div>
  );
};

export default memo(MyCoursesFilters);
