import dynamic from 'next/dynamic';

import { Container } from '@crehana/ui';

import CourseListSkeleton from './components/CourseListSkeleton';

const LazyCoursesWithRules = dynamic(
  () =>
    import(
      /* webpackChunkName: "user-dashboard-courses-rules" */ './MyCoursesWithRules'
    ),
  {
    loading: () => (
      <Container type="dashboard">
        <CourseListSkeleton />
      </Container>
    ),
    ssr: false,
  },
);

export default LazyCoursesWithRules;
